<template>
  <div>
    <Button type="primary" class="mb-15" @click="showAdd">新增库存</Button>
    <Table :data="tableData" border style="width: 100%">
      <TableColumn label="库存地址">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.date }}</span>
        </template>
      </TableColumn>
      <TableColumn label="学校名称">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.date }}</span>
        </template>
      </TableColumn>
      <TableColumn label="服装类型">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.date }}</span>
        </template>
      </TableColumn>
      <TableColumn label="服装尺寸">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.date }}</span>
        </template>
      </TableColumn>
      <TableColumn label="操作" width="180">
        <template slot-scope="scope">
          <Button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</Button
          >
          <Button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</Button
          >
        </template>
      </TableColumn>
    </Table>

    <Dialog :title="dialogFormTitle" :visible.sync="dialogFormVisible">
      <Form ref="form" :model="form" label-width="80px">
        <FormItem label="库存地址">
          <Select v-model="form.region" placeholder="请选择库存">
            <Option label="区域一" value="shanghai"></Option>
            <Option label="区域二" value="beijing"></Option>
          </Select>
        </FormItem>
        <FormItem label="学校名称">
          <Select v-model="form.region" placeholder="请选择学校">
            <Option label="区域一" value="shanghai"></Option>
            <Option label="区域二" value="beijing"></Option>
          </Select>
        </FormItem>
        <FormItem label="服装类型">
          <Select v-model="form.region" placeholder="请选择学校">
            <Option label="区域一" value="shanghai"></Option>
            <Option label="区域二" value="beijing"></Option>
          </Select>
        </FormItem>
        <FormItem label="服装尺寸">
          <Select v-model="form.region" placeholder="请选择学校">
            <Option label="区域一" value="shanghai"></Option>
            <Option label="区域二" value="beijing"></Option>
          </Select>
        </FormItem>
        <FormItem label="库存">
          <InputNumber
            v-model="num"
            @change="handleChange"
            :min="0"
            label="描述文字"
          ></InputNumber>
        </FormItem>
      </Form>
      <div slot="footer" class="dialog-footer">
        <Button @click="dialogFormVisible = false">取 消</Button>
        <Button type="primary" @click="dialogFormVisible = false">确 定</Button>
      </div>
    </Dialog>
  </div>
</template>
<script>
import {
  Button,
  Table,
  TableColumn,
  Dialog,
  Form,
  FormItem,
  Select,
  Option,
  InputNumber,
} from "element-ui";
export default {
  name: "SideNav",
  components: {
    Table,
    TableColumn,
    Button,
    Dialog,
    Form,
    FormItem,
    Select,
    Option,
    InputNumber,
  },
  data() {
    return {
      dialogFormVisible: false,
      formLabelWidth: "100",
      dialogFormTitle: "新增库存",
      tableData: [],
      form: {},
    };
  },
  methods: {
    showAdd() {
      this.dialogFormVisible = true;
      this.dialogFormTitle = "新增库存";
      this.form = {};
    },
    handleEdit(e) {
      console.log(e);
    },
    handleDelete(e) {
      console.log(e);
    },
    handleChange() {},
  },
};
</script>
<style scoped>
.mb-15 {
  margin-bottom: 15px;
}
</style>
